import { Button, CoreDialog, CssPosition } from '@dierbergs-markets/react-component-library';
import { Box } from '@mui/material';
import React, { useState } from 'react';

interface DeltaLogButtonProps {
  deltaLog?: string[];
}

const DeltaLogButton: React.FC<DeltaLogButtonProps> = ({ deltaLog }) => {
  const [showDeltaLogDialog, setShowDeltaLogDialog] = useState<boolean>(true);

  const handleDialogClose = () => {
    setShowDeltaLogDialog(false);
  };

  const toggleDialog = () => {
    setShowDeltaLogDialog(!showDeltaLogDialog);
  };

  const defaultWidth = 550;
  const defaultHeight = 400;
  const startPosition: CssPosition = {
    left: (window.innerWidth - defaultWidth) / 2,
    right: (window.innerWidth - defaultWidth) / 2,
    top: (window.innerHeight - defaultHeight) / 2,
    bottom: (window.innerHeight - defaultHeight) / 2,
  };

  if (!deltaLog || deltaLog.length === 0) return <></>;

  return (
    <>
      <CoreDialog
        id={'DeltaLogDialog'}
        open={true}
        onClose={handleDialogClose}
        isModal={false}
        nonModalProps={{
          startPosition,
        }}
      >
        <Box sx={styles.header}>Proposal Changes</Box>
        <ul>
          {deltaLog.map((q, i) => {
            return <li key={i}>{q}</li>;
          })}
        </ul>
      </CoreDialog>
      <Button id="DeltaLog" variant={'rounded-sides'} color="black" text="View Proposal Changes" onClick={toggleDialog}></Button>
    </>
  );
};

const styles = {
  header: {
    textAlign: 'center',
    fontSize: '25px',
    paddingTop: '10px',
  },
};

export default DeltaLogButton;
