import { IContract, IPricing, IPricingItem } from '../../../../../models';
import { BaseCustomExportFormProps, DataGridCellMessageMap, DataGridRenderBodyCellParams } from '@dierbergs-markets/react-component-library';
import * as PG from '@dierbergs-markets/react-feature-library';
import { tss } from 'tss-react';
import { CurrencyCellContent, PricingGrid } from '@dierbergs-markets/react-feature-library';
import { memo, useEffect, useState } from 'react';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import { defaultColors } from '../../../../../styles/variables';
import { getPricings, PricingGridPricingItem, toPricingGridPricingItem } from '../../../../../utilities/PricingGridUtilities';

interface IProps {
  contract: IContract;
  canModify: boolean;
  contractLoading: boolean;
  onPricingEdit: (price: IPricing) => void;
  onRowUpdate: (row: PG.IPricingItem, messages?: DataGridCellMessageMap) => void;
  onMultiRowUpdate: (rows: PG.IPricingItem[]) => void;
  onPricingItemsUpdate: (pricingIndex: number, items: IPricingItem[]) => void;
}

const PricingGridContainer = memo((props: IProps) => {
  // STATE
  const [gridRows, setGridRows] = useState<PricingGridPricingItem[]>([]);
  const [gridMessages, setGridMessages] = useState<DataGridCellMessageMap | undefined>(undefined);

  // MISC. HOOKS
  const { referenceData, user } = useApplicationContextState();
  const { classes } = useStyles();

  // LOCAL VARS

  //EFFECTS
  useEffect(() => {
    setGridRows(props.contract.terms.contractItems.map((ci) => toPricingGridPricingItem(props.contract, ci)));
  }, [props.contract.terms.contractItems, props.contract.pricings, referenceData]);

  //FUNCTIONS
  function handleRowFinalize(row: any, messages?: DataGridCellMessageMap) {
    props.onRowUpdate(row, messages);
    setGridMessages(messages);
  }

  function invokePricingEdit(pricing: IPricing) {
    props.canModify && props.onPricingEdit(pricing);
  }

  return (
    <div className={classes.root}>
      {referenceData && gridRows.length > 0 && (
        <PricingGrid
          id={'PricingGrid'}
          prices={getPricings(props.contract, referenceData, classes, invokePricingEdit, props.onPricingItemsUpdate)}
          findReplaceEnabled={true}
          columnOrderable
          maxBodyHeightOffset={496}
          headerRowHeight={160}
          rows={gridRows}
          actionConfig={[{ position: 'bottom', actions: ['Columns', 'Sort'], alignment: 'left' }]}
          processRowUpdate={handleRowFinalize}
          processMultiRowUpdate={props.onMultiRowUpdate}
          preferences={{
            columns: {
              visibilityMap: {
                suggestedRetail: true,
              },
            },
            columnOrder: {
              suggestedRetail: 10.1,
            },
          }}
          additionalColumns={[
            {
              field: 'suggestedRetail',
              headerName: 'Sugg Retail',
              type: 'string',
              width: 95,
              renderBodyCellContent(params: DataGridRenderBodyCellParams<number, PricingGridPricingItem>) {
                if (!params.row.suggestedRetailPrice) return <></>;
                return <CurrencyCellContent value={params.row.suggestedRetailPrice} multiple={params.row.suggestedRetailMultiple} />;
              },
            },
          ]}
          costImplicationGroupedHeaderCellCss={{
            root: {
              backgroundColor: defaultColors.white,
              borderRadius: '16px 16px 0 0',
              outline: `1px solid ${defaultColors.grey}`,
              fontSize: '16px',
              paddingTop: '13px',
              height: '150px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
            subRow: {
              marginTop: '10px',
            },
          }}
        />
      )}
    </div>
  );
});

export default PricingGridContainer;

const useStyles = tss.withParams().create({
  root: {},

  headerContents: {
    margin: '15px',
    marginBottom: '10px',
    marginLeft: '20px',
    width: '100%',
  },
  headerComments: {
    width: '280px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerEditBtn: {
    '&&': {
      fontWeight: 500,
      lineHeight: '19px',
      fontSize: '16px',
      color: defaultColors.blue,
      paddingBottom: '5px',
    },
  },
  suggestedRetail: {
    paddingTop: '8px',
  },
  pricePrefix: {
    paddingRight: '8px',
    fontWeight: 500,
  },
});
