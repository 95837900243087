import { Box } from '@mui/material';
import { IContract, IContractTerms } from '../../../../models';
import { defaultColors } from '../../../../styles/variables';
import { DbgLinkButton } from '../../../components/buttons/DbgLinkButton';
import { useApplicationContextState } from '../../../../contexts/ApplicationContext';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ValidationIssue } from '../../../../classes/ValidationIssue';
import { useContractShowErrorsStore } from '../../../../store';
import { ValidationScopes } from '../../../../models/enums';

interface IProps {
  contract: IContract;
  contractTerms: IContractTerms;
  editContract: () => void;
  errors?: ValidationIssue[];
}

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    minHeight: '60px',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${defaultColors.border}`,
  },
  detail: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingX: '15px',
    paddingY: '10px',
    textAlign: 'left',
    borderRight: `1px solid ${defaultColors.border}`,
    fontWeight: 500,
  },
  detailError: {
    outline: `2px solid ${defaultColors.red}`,
  },
  name: {
    fontSize: '12px',
    lineHeight: '15px',
    marginBottom: '4px',
    color: defaultColors.mediumGrey,
    whiteSpace: 'nowrap',
  },
  value: {
    fontSize: '15px',
    lineHeight: '19px',
  },
  comments: {
    width: '280px',
  },
  contractId: {
    minWidth: '210px',
  },
  edit: {
    minWidth: '55px',
    maxWidth: '55px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editIcon: {
    color: defaultColors.blue,
    fontSize: '18px',
  },
  emptyContractId: {
    backgroundColor: defaultColors.lightGrey,
  },
  rightAlign: {
    textAlign: 'right',
  },
  missingInfoBtn: {
    color: defaultColors.red,
    textDecoration: 'underline',
    fontSize: '15px',
    lineHeight: '19px',
    fontWeight: 500,
    '&:hover': {
      color: defaultColors.red,
      textDecoration: 'underline',
    },
  },
  missingInfoIcon: {
    height: '19px',
  },
};

const columnWidths = {
  vendorContractReferenceId: '206px',
  customerNo: '130px',
  customerName: '100%',
  rep: '130px',
  manufacturer: '100%',
  supplier: '100%',
  productLine: '100%',
  comments: '100%',
  categoryManager: '175px',
  stores: '130px',
  contractId: '130px',
};

const getWidthStyles = (width: string) => {
  if (width === '100%') {
    return {
      width: width,
    };
  } else {
    return {
      width: width,
      minWidth: width,
      maxWidth: width,
    };
  }
};

const columnWidthStyles = {
  vendorContractReferenceId: getWidthStyles(columnWidths.vendorContractReferenceId),
  customerNo: getWidthStyles(columnWidths.customerNo),
  customerName: getWidthStyles(columnWidths.customerName),
  rep: getWidthStyles(columnWidths.rep),
  manufacturer: getWidthStyles(columnWidths.manufacturer),
  supplier: getWidthStyles(columnWidths.supplier),
  productLine: getWidthStyles(columnWidths.productLine),
  comments: getWidthStyles(columnWidths.comments),
  categoryManager: getWidthStyles(columnWidths.categoryManager),
  stores: getWidthStyles(columnWidths.stores),
  contractId: getWidthStyles(columnWidths.contractId),
};

export default function ContractHeader(props: IProps) {
  const { referenceData } = useApplicationContextState();
  const { showErrors } = useContractShowErrorsStore();

  const getErrorClass = (field: string): any => {
    if (!showErrors) return undefined;
    if (props.errors?.find((q) => q.scope === ValidationScopes.ContractHeader && q.field === field)) return styles.detailError;
  };

  function getStoresLabel() {
    const numStores = props.contractTerms.stores.storeIds.length;
    const storeText = `Store${numStores > 1 ? 's' : ''}`;
    const numStoresGroups = props.contractTerms.stores.storeGroupIds.length;
    const storeGroupText = numStoresGroups > 0 ? referenceData?.storeGroups[props.contractTerms.stores.storeGroupIds[0]].displayName : ``;
    if (numStores > 0) return `${numStores} ${storeText}`;
    else if (numStoresGroups > 0) return `${storeGroupText}`;
    else return `No stores`;
  }

  return (
    <Box sx={styles.root}>
      <Box sx={[styles.detail, columnWidthStyles.vendorContractReferenceId, getErrorClass('VendorContractNumber')]}>
        <Box sx={styles.name}>Contract #</Box>
        <Box sx={styles.value}>
          {props.contractTerms.vendorContractNumber ?? (
            <DbgLinkButton id={'ContactMissingVendorContractNumber'} sx={styles.missingInfoBtn} onClick={props.editContract}>
              <ErrorOutlineIcon sx={styles.missingInfoIcon} /> Missing
            </DbgLinkButton>
          )}
        </Box>
      </Box>
      <Box sx={[styles.detail, columnWidthStyles.productLine, getErrorClass('ProductLine')]}>
        <Box sx={styles.name}>Product Line</Box>
        <Box sx={styles.value}>{props.contractTerms.productLine}</Box>
      </Box>
      <Box sx={[styles.detail, columnWidthStyles.customerNo, getErrorClass('CustomerId')]}>
        <Box sx={styles.name}>Bill to Account #</Box>
        <Box sx={styles.value}>
          {props.contractTerms.customer?.customerId ?? (
            <DbgLinkButton id={'ContactMissingCustomerNumber'} sx={styles.missingInfoBtn} onClick={props.editContract}>
              <ErrorOutlineIcon sx={styles.missingInfoIcon} /> Missing
            </DbgLinkButton>
          )}
        </Box>
      </Box>
      <Box sx={[styles.detail, columnWidthStyles.customerName, getErrorClass('CustomerId')]}>
        <Box sx={styles.name}>Bill to Acct Name</Box>
        <Box sx={styles.value}>
          {props.contractTerms.customer?.name ?? (
            <DbgLinkButton id={'ContactMissingCustomerName'} sx={styles.missingInfoBtn} onClick={props.editContract}>
              <ErrorOutlineIcon sx={styles.missingInfoIcon} /> Missing
            </DbgLinkButton>
          )}
        </Box>
      </Box>
      {/* <Box sx={[styles.detail, columnWidthStyles.rep]}>
        <Box sx={styles.name}>Rep</Box>
        <Box sx={styles.value}>Placeholder</Box>
      </Box> */}
      <Box sx={[styles.detail, columnWidthStyles.manufacturer, getErrorClass('ManufacturerId')]}>
        <Box sx={styles.name}>Manufacturer</Box>
        <Box sx={styles.value}>
          {props.contractTerms.manufacturer?.name ?? (
            <DbgLinkButton id={'ContactMissingManufacturer'} sx={styles.missingInfoBtn} onClick={props.editContract}>
              <ErrorOutlineIcon sx={styles.missingInfoIcon} /> Missing
            </DbgLinkButton>
          )}
        </Box>
      </Box>
      <Box sx={[styles.detail, columnWidthStyles.supplier, getErrorClass('SupplierId')]}>
        <Box sx={styles.name}>Supplier</Box>
        <Box sx={styles.value}>{props.contractTerms.supplier?.name ?? 'None'}</Box>
      </Box>
      <Box sx={[styles.detail, columnWidthStyles.categoryManager, getErrorClass('CategoryManagerId')]}>
        <Box sx={styles.name}>Category Manager</Box>
        <Box sx={styles.value}>
          {props.contractTerms.categoryManager?.name ?? (
            <DbgLinkButton id={'ContactMissingCategoryManager'} sx={styles.missingInfoBtn} onClick={props.editContract}>
              <ErrorOutlineIcon sx={styles.missingInfoIcon} /> Missing
            </DbgLinkButton>
          )}
        </Box>
      </Box>
      <Box sx={[styles.detail, columnWidthStyles.stores]}>
        <Box sx={styles.name}>{props.contractTerms.stores.storeGroupIds.length === 1 ? 'Store Group' : 'Stores'}</Box>
        <Box sx={styles.value}>{getStoresLabel()}</Box>
      </Box>
      <Box sx={[styles.detail, columnWidthStyles.contractId, styles.rightAlign, props.contract.contractId === 0 && styles.emptyContractId]}>
        <Box sx={styles.name}>Internal Contract #</Box>
        <Box sx={styles.value}>{props.contract.contractId !== 0 && props.contract.contractId}</Box>
      </Box>
      <Box sx={[styles.detail, columnWidthStyles.comments, getErrorClass('Comments')]}>
        <Box sx={styles.name}>Comments</Box>
        <Box sx={styles.value}>
          <Box sx={styles.comments} title={props.contractTerms.comments}>
            {props.contractTerms.comments}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
